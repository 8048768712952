
import '../src/commponents/default.css';
import { Typography,Step,Stepper,StepLabel, TextField, Button, Box} from '@mui/material';
import React, { useState, useEffect } from "react";

const Login = () => {

  const [mail,setMail] = useState("");
  const [password,setPassword] = useState("");
  const [error, setError] = useState('');

  function handleSubmit(event) {
    event.preventDefault(); // デフォルトの送信動作をキャンセルする
    console.log(mail);
    console.log(password);
    // バリデーション処理
    if (!mail || !password) {
      setError("※メールアドレス、パスワードを入力してください");
      return;
    }
    // フォームの送信処理
    fetch("/api/login.php", {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ mail:mail,password:password }),
    })
      .then(response => {
        if (response.status == 200) {
          window.location.href = "/Admin";
          console.log("ログイン成功");
        }
        else {
          setError("※メールアドレス、パスワードが間違っています");
        }
        return response.text();
      })
      .then(data => {
        console.log(data);
      })
      .catch(error => {
        console.log(error);
        setError("※メールアドレス、パスワードが間違っています");
      });
  }
    
      return (
        <>
        {/* <NavigationMenu/> */}
          <div className='center-content'>
          <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={handleSubmit}>
          <Typography style={{ fontWeight: 'bold', margin: '10px 0 20px', textAlign: 'center' }} component="h4" variant="h4">
            ログイン
          </Typography>
          {error && <p style={{ color: 'red', fontSize: '90%' }}>{error}</p>}
          <div>
            <TextField
                margin="normal"
                fullWidth
                type="text"
                label="メールアドレス"
                value={mail}
                onChange={e => setMail(e.target.value)}
              />
              <TextField
                margin="normal"
                fullWidth
                type="text"
                label="パスワード"
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
            </div>
            <Button
              type="submit"
              variant="contained"
              style={{backgroundColor:'#282c34'}}
              sx={{ mt: 3, mb: 2 }}
            >
              ログイン
            </Button>
          </Box>
          </div>
        </>
      );
    };
  export default Login;