import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './Login';
import ApplicationForm from './ApplicationForm';
import Document from './Document';
import Admin from './Admin';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/"  component={<Login />} />
        <Route path="/ApplicationForm" component={<ApplicationForm />} />
        <Route path="/Admin" element={<Admin />} />
        <Route path="/Document" element={<Document />} />
      </Routes>
    </Router>
  );
}

export default App;
